@import '../../assets//styles/partials/mixins.scss';
@import '../../assets//styles/partials/variables.scss';

.wrapper {
  display: flex;
  flex-direction: column;

  > .label {
    text-align: left;
    position: relative;
    font-size: $font-size;
    color: $text-color;
    margin: 5px 0 5px 0;
  }

  .input {
    border-radius: 4px;
    background-color: $white-color;
    padding: 0px 11px;
    font-size: $font-size-small;
    color: $text-color;

    border: 1px solid $line-color;

    box-sizing: border-box;
    outline: 0;
    height: 36px;
    width: 100%;

    &:focus {
      outline: 0;
    }

    &::after {
      content: '';
      width: 100%;
      height: 33px;
    }

    @include screen(max-sm) {
      font-size: 16px;
    }
  }

  .round {
    border-radius: 16px;
  }
}

.input.icon {
  border-right: none;
}

.wrapper.error {
  .input {
    border-color: $border-color-error;
  }

  .label {
    color: $text-red-blue-color;
  }

  .span {
    text-align: end;
    color: $text-red-blue-color;
    font-size: $font-size-small;
  }
}

.xIcon {
  cursor: pointer !important;
  margin-top: 3px;
  margin-right: 3px;
  height: 15px;
  width: 15px;
  color: $primary-color;

  &.searchIcon {
    width: 21px;
    height: 21px;
  }

  &.xInvertedIcon {
    -webkit-transform: scaleX(-1);
    -moz-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    transform: scaleX(-1);

    filter: FlipH;
    -ms-filter: 'FlipH';
  }
}

.inputWrapper {
  position: relative;

  > input {
    padding-right: 35px;
  }
}

.adornment {
  position: absolute;
  box-sizing: border-box;
  right: 10px;
  transform: translateY(3px);
}
