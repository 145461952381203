@import '../../assets//styles/partials/mixins.scss';
@import '../../assets//styles/partials/variables.scss';

div {
  &.pagimovelLogoContainer {
    > a {
      width: 100%;
      height: 100%;

      > img {
        width: 100%;
        height: 100%;
        transition: filter 400ms ease-in-out;
        &:hover{
          filter: opacity(0.8);
        }

        @media (min-width: 800px) {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
