@import '../../assets//styles/partials/mixins.scss';
@import '../../assets//styles/partials/variables.scss';

.footer {
  @include flexBox(column);
  margin: 0 auto;
  width: 100%;
  padding: 48px 24px;
  text-align: center;
  bottom: calc(-20vh - 10%);

  @media (min-width: 800px) {
    bottom: -200px;
    position: absolute;

  }

  p {
    > a {
      text-decoration: none;
      font-weight: 700;
      color: $primary-color;
    }
  }
}
