@import '../../assets//styles/partials/mixins.scss';
@import '../../assets//styles/partials/variables.scss';

.styledTitle {
  color: $primary-color;
  font-size: $font-size-med;
  text-transform: uppercase;
  margin: 20px 0;
  font-weight: bolder;
  text-decoration: underline;
}
