@import '../../assets//styles/partials/mixins.scss';
@import '../../assets//styles/partials/variables.scss';

.burgerContainer {
  width: 2rem;
  height: 2rem;
  position: absolute;
  top: 15px;
  right: 30px;
  cursor: pointer;
  @include flexBox(column, space-around);
  z-index: 10;
  @media (min-width: 800px) {
    display: none;
  }

  div {
    width: 2rem;
    height: 0.25rem;
    background-color: $blue-bb;
    border-radius: 10px;
    transform: rotate(0);
    transform-origin: 1px;
    transition: all 200ms ease-in-out;
    z-index: 5;

    &.active {
      background-color: #333;
      transform: rotate(45deg);
    }

    &.mid {
      transform: translateX(0);
      opacity: 1;
    }
    &.activeMid {
      background-color: #333;
      transform: translateX(100%);
      opacity: 0;
    }

    &.last {
      transform: rotate(0);
    }

    &.activeLast {
      background-color: #333;
      transform: rotate(-45deg);
    }
  }
}
