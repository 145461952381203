@import '../../assets//styles/partials/mixins.scss';
@import '../../assets//styles/partials/variables.scss';

.cardsTitle {
  color: $primary-color;
  font-size: $font-size-xlg;
  line-height: 33px;
  margin-bottom: 16px;
  position: relative;
  bottom: 0px;

  padding: 2.5% 5%;

  @media (min-width: 800px) {
    padding: 0;
    margin: 0 10%;
    bottom: -10vh;
  }
}
.swiperContainer {
  width: 100%;
  max-width: 1388px;
  overflow: hidden;
  list-style-type: none;
  margin: auto;

  @media (min-width: 800px) {
    margin-top: 15vh;
  }
}

.slider {
  list-style-type: none;
  display: flex;
  text-align: center;
  flex-direction: row;
  padding-top: 20px;
}

.timestamp {
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 32px;
  font-weight: 100;
}

@include screen(xs) {
  .timestamp {
    max-width: 50vw;

    img {
      max-width: 50vw;
      -webkit-transform: scale(0.999);
      transform: scale(0.999);
    }
  }
}

@include screen(sm) {
  .timestamp {
    max-width: 28vw;

    img {
      max-width: 28vw;
    }
  }
}

@include screen(md) {
  .timestamp {
    max-width: 20vw;

    img {
      max-width: 20vw;
    }
  }
}

@include screen(lg) {
  .timestamp {
    max-width: 18vw;

    img {
      max-width: 18vw;
    }
  }
}

@include screen(xl) {
  .timestamp {
    max-width: 14vw;

    img {
      max-width: 14vw;
    }
  }
}

@include screen(xxl) {
  .timestamp {
    max-width: 12vw;

    img {
      max-width: 12vw;
    }
  }
}

.swiperButton {
  all: unset;
  cursor: pointer;
  // all:unset specified for IE
  background: none;
  border: none;
  padding: 0;
  outline: inherit;
  transition: filter 400ms ease-in-out;
  &:hover {
    filter: opacity(0.7);
  }
}

.buttonContainer {
  display: flex;
  width: 100%;
  max-width: 1388px;
  justify-content: space-between;
  position: absolute;
  margin-top: 18px;

  .arrow {
    width: 48px;
    border: 1px solid #dadada;
    border-radius: 50%;
    padding: 16px;
    color: #333;
    transition: filter 400ms ease-in-out;
    &:hover {
      filter: opacity(0.6);
    }
  }
}

.button {
  z-index: 1;
  display: flex;
  align-items: center;
  text-decoration: none;
  border: 0;
  background: none;
  cursor: pointer;
  margin: 0px;

  &:focus {
    outline: none;
  }

  @include screen(xs) {
    margin: 0px 5px;
  }
}
