@import '../../assets/styles/partials/mixins.scss';
@import '../../assets/styles/partials/variables.scss';

section {
  &.disputesContainer {
    @include flexBox(column);
    width: 100%;
    background-color: #f4f4f4;
    padding: 2.5% 5%;

    @media (min-width: 800px) {
      @include flexBox(row, space-between);
      padding: 2.5% 10%;
      max-height: 260px;
    }
    .info {
      width: 100%;
      h3 {
        color: $primary-color;
        font-size: $font-size-xlg;
        line-height: 33px;
        margin-bottom: 16px;
      }
      p {
        font-size: $font-size-med;
        line-height: 25px;
        color: $text-color;
        max-width: 100%;

        @media (min-width: 800px) {
          max-width: 45ch;
        }
      }
    }

    .portalContainer {
      @include flexBox(column);
      width: 100%;
      @media (min-width: 800px) {
        @include flexBox(row, space-between);
        width: 100%;
        max-width: 700px;
      }
      span {
        display: none;
        @media (min-width: 800px) {
          display: inline-block;
          height: 163px;
          border-right: 1px solid #dadada;
        }
      }
      .portal {
        @include flexBox(column);
        width: 100%;
        margin-bottom: 32px;
        img {
          width: 136px;
          height: 42px;
          margin-bottom: 32px;
        }
        a {
          background-color: $blue-bb;
          color: white;
          font-weight: 400;
          text-decoration: none;
          width: 100%;
          padding: 12px 56px;
          text-align: center;

          @media (min-width: 800px) {
            padding: 12px 27px;
            margin-bottom: 0;
            width: auto;
          }
        }
      }
    }
  }
}
