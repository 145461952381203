@import '../../assets//styles/partials/mixins.scss';
@import '../../assets//styles/partials/variables.scss';

.toast {
  padding: 1em !important;
  border-radius: 4px !important;
  background: white !important;
  box-shadow: 2px 2px 5px black;
  color: $text-color !important;
}

.success {
  background: $primary-color !important;
}

.error {
  background: $red-blue-color !important;
}
