@import '../../assets//styles/partials/mixins.scss';
@import '../../assets//styles/partials/variables.scss';

.subjectDropdown {
  display: flex;
  flex-direction: column;

  > label {
    > span {
      > select {
        border-radius: 4px;
        background-color: $white-color;
        padding: 0px 11px;
        width: 100%;
        box-sizing: border-box;
        outline: 0;
        height: 40px;
        width: 100%;
        font-weight: normal;
        margin-top: 5px;
        font-size: $font-size-small;
        color: $dark-blue-color;

        &:focus {
          outline: 0;
        }
      }
    }
  }
}

.SubjectDropdown.error {
  > label {
    > span {
      select {
        border-color: $border-color-error;
      }

      .label {
        color: $text-red-blue-color;
      }
    }
  }
  .span {
    align-self: flex-end;
    color: $text-red-blue-color;
    font-size: $font-size;
  }
}
.titleError {
  color: $text-red-blue-color;
}
.title {
  color: #333;
}
#spanError {
  color: $text-red-blue-color;
  font-size: $font-size-small;
  text-align: end;
}
