@import '../../assets//styles/partials/mixins.scss';
@import '../../assets//styles/partials/variables.scss';

nav {
  &.navContainer {
    background-color: #f4f4f4;

    @media (min-width: 800px) {
      background-color: transparent;
    }

    ul {
      @include flexBox(row, space-between);
      background-color: transparent;
      transition: transform 0.4 ease-in-out;

      @media (max-width: 800px) {
        @include flexBox(column, flex-start, center);
        display: none;
        position: fixed;
        top: 0;
        right: 0;
        height: 100vh;
        width: 70vw;
        background-color: $white-color;
        transform: translateX(100%);
        z-index: 5;

        &.activeUl {
          display: flex;
          transform: translateX(0);
        }
      }

      li {
        margin-top: 32px;
        @include flexBox(row, space-between);
        @media (min-width: 800px) {
          min-width: 90px;
          margin-top: 0;
        }

        .link {
          text-decoration: none;

          @media (min-width: 800px) {
            // margin-right: 36px;
            font-size: $font-size-small;
            line-height: 19px;
          }
          &:hover{
            filter: opacity(0.7);
          }
        }
        .active {
          color: $primary-color;
          font-weight: 700;

        }
      }
    }
  }
}
