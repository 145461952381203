@import '../../assets//styles/partials/mixins.scss';
@import '../../assets//styles/partials/variables.scss';

.contactInfoContainer {
  @include flexBox(column);

  @media (min-width: 800px) {
    @include flexBox(column, space-between, flex-start);
    max-width: 40vw;
    width: 100%;
    height: 100%;
    align-self: flex-start;
  }

  .title {
    font-size: $font-size-med;
    color: #000;
    line-height: 25px;
    // margin-bottom: 24px;
  }

  .subTitle {
    color: $text-color;
    font-weight: 600;
    font-size: $font-size;
    line-height: 19px;
    margin-block-start: 0;
    margin-block-end: 0.5rem;
  }

  .commonText {
    font-size: $font-size-small;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-bottom: 2rem;
  }
}
