@import '../../assets/styles/partials/mixins.scss';
@import '../../assets/styles/partials/variables.scss';

header {
  &.headerContainer {
    width: 100%;
    padding: 2% 5%;
    @include flexBox(row, space-between);

    @media (min-width: 800px) {
      @include flexBox(row, space-between);
      padding: 50px 70px;
    }
  }
}
