@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');

$primary-color: #00bfb2;
$background-color: #f9f9f9;
$white-color: #ffffff;

$grey-text: #676b8b;
$text-color: #444444;
$dark-color: #031733;
$blue-bb: #002d72;

$line-color: #dadade;

//resale
$dark-blue-color: #23355a;
$light-blue-color: #65708b;
$bb-blue: #215297;

$white-color: #ffffff;
$red-blue-color: #fb4e5f;
$bar-grey: #dadadf;
$border-color-grey: $bar-grey;
$border-color-error: $red-blue-color;
$border-grey: #e0e0e0;
$border-very-light-blue: #e9ebee;
$very-light-gray-color: #efefef;

$text-primary-color: $primary-color;
$text-light-blue-color: $light-blue-color;
$text-red-blue-color: $red-blue-color;
$text-black-color: #000000;
$text-color: #454545;

$line-color: #dadade;

$font-size-xxsmall: 10px;
$font-size-xsmall: 12px;
$font-size-small: 14px;
$font-size: 16px;
$font-size-med: 18px;
$font-size-lg: 20px;
$font-size-xlg: 24px;
$font-size-xxlg: 30px;
$font-size-32: 32px;
$font-size-35: 35px;
$font-size-36: 36px;
$font-size-xxxlg: 40px;
$font-size-xxxxlg: 60px;
$font-size-small: 14px;
$font-size: 16px;
$font-size-med: 18px;
$font-size-lg: 20px;
$font-size-xlg: 24px;
$font-size-xxlg: 30px;
$font-size-32: 32px;
$font-size-35: 35px;

$heading-1: 2.488rem;
$heading-2: 2.074rem;
$heading-3: 1.728rem;
$heading-4: 1.44rem;
$heading-5: 1.2rem;
$heading-6: 1rem;

$padding-global-left: 55px;
$padding-global-right: 45px;
$font-family: 'Open Sans', sans-serif;
